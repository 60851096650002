<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">设备重启日志</h3>
          <div class="table-btns">
            <a-button type="primary" @click="deleteBatch()">删除</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>

        <a-table :row-selection="{onChange: rowSelectChange}" :columns="tableColumns" :data-source="tableData" row-key="recoverid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="detail" slot-scope="value,record">
            <a-button type="link" size="small" @click="showUpdateDetail(value,record)">详情</a-button>
          </span>
          <span slot="recoverdate" slot-scope="recoverdate">
            <span>{{recoverdate?moment(recoverdate, 'YYYYMMDDhhmmss').format('YYYY-MM-DD HH:mm:ss'):''}}</span>
          </span>
          <span slot="operation" slot-scope="value, record, index">
            <a-button type="link" size="small" @click="deleteConfirm(value, record, index)">删除</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <recover-detail :visible.sync="recoverdetailVisible" ref="recoverDetail" :RecoverDetail="recoverDetail"></recover-detail>
  </page-container>
</template>

<script>
import pagination from '@/mixins/pagination'
import {deleteDevparamrecord, deleteRecoverLog, findRecoverLogListByCondition,} from "A/ai";
import moment from "moment";
import RecoverDetail from "V/monitorDevice/monitoringEquipment/recoverLog/RecoverDetail";
import {getCache} from "U/index";
export default {
  name:'recoverlog',
  mixins: [pagination],
  components: {
    RecoverDetail,
  },
  data() {
    return {
      params:'',
      moment,
      recoverid:[],
      recoverDetail:'',
      batchrowids: '',
      queryParams: {
        username:'',
        recoverdate:'',
        recoverobj:'',
        orderresult:'',
        recoverid:'',
      },
      tableColumns: [
        {
          title: '重启指令下发者',
          dataIndex: 'username',
          key: 'username',
          ellipsis: true,
        },
        {
          title: '重启指令下发时间',
          dataIndex: 'recoverdate',
          key: 'recoverdate',
          ellipsis: true,
          scopedSlots: { customRender: 'recoverdate' }
        },
        {
          title: '重启对象',
          dataIndex: 'recoverobj',
          key: 'recoverobj',
          ellipsis: true,
        },
        {
          title: '重启指令下发结果',
          dataIndex: 'orderresult',
          key: 'orderresult',
          ellipsis: true,
        },
        {
          title: '重启详情',
          key: 'detail',
          width: 70,
          align: 'center',
          scopedSlots: { customRender: 'detail' }
        },
        {
          title: '操作',
          key: 'operation',
          width: 70,
          align: 'center',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      devicenum: [],
      devicegroupid:'',
      recoverdetailVisible:false,
      flag:false,
      paramMap:[],
    }
  },
  computed: {
    pageTitle() {
      return '设备重启日志';
    },
    breadcrumb() {
      return [
        {
          name: '网络管理',
          path: ''
        },
        {
          name: this.pageTitle,
          path: ''
        },
      ]
    },
  },
  created(){
    this.getTableData()
  },
  methods: {
    showUpdateDetail(value,record){
      // this.$refs.recoverDetail.queryParams.recoverid = record.recoverid
      // this.$refs.recoverDetail.queryParams.devicegroupid = record.devicegroupid
      this.recoverDetail=record;
      this.recoverdetailVisible=true;
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
        this.usertype = logininfo.usertype;
      }
      this.tableLoading = true;
      let params={
        recovertype: '0',
        username: this.usertype==0?"":this.username,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      }
      findRecoverLogListByCondition(params).then(res=>{
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      })
    },
    rowSelectChange(selectedRowKeys, selectedRows) {
      this.batchrowids = selectedRowKeys.join('|');
    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm('确定要删除选中数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(this.batchrowids);
      }).catch(()=>{
      });
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.recoverid);
      }).catch(()=>{
      });
    },
    delete(recoverid) {
      this.showLoading();
      if(recoverid) {
        let params = {
          recoverid
        };
        deleteRecoverLog(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
  },
}
</script>
